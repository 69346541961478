import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  // Define Ad unit names
  const RightColumnUnitName = "DESK_RightColumn";
  const LeftColumnUnitName = "DESK_LeftColumn";
  const MPUTopRightUnitName = "MOB_MPUTopRight";
  let LeaderBelowTitleUnitName = "DESK_LeaderBelowTitle";
  if (window.screen.width < 812) {
    LeaderBelowTitleUnitName = "MOB_LeaderBelowTitle";
  }

  // Define Ad Unit Pathways
  const RightColumnPathway = "TVLFR/DESK_RightColumn";
  const LeftColumnPathway = "TVLFR/DESK_LeftColumn";
  let MPUTopRightPathway = "TVLFR/DESK_MPUTopRight";
  let LeaderBelowTitlePathway = "TVLFR/DESK_LeaderBelowTitle";
  if (window.screen.width < 812) {
    MPUTopRightPathway = "TVLFR/MOB_MPUTopRight";
    LeaderBelowTitlePathway = "TVLFR/MOB_LeaderBelowTitle";
  }

  // prettier-ignore
  return [
    {
      "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      "id": "LeaderBelowTitle",
      "name": LeaderBelowTitleUnitName,
      "path": `/76730613/${LeaderBelowTitlePathway}`,
      "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[728, 90]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'LeaderBelowTitle', environment:'desktop', placement:`${LeaderBelowTitlePathway}` } },
        { bidder: 'adform', params: { mid: 979836 } },
        //{ bidder: 'appnexus', params: { placementId: '20942249' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        //{ bidder: 'openx',  params: { unit: '540973692', delDomain: 'lqr-d.openx.net' } },
        //{ bidder: 'districtm', params: { placementId: 16005304  } },
        //{ bidder: 'districtmDMX', params: { dmxid: 367610, memberid : 101868 } },
        { bidder: 'ix', params: {siteId: 623518, size: [728,90]} },
        //{ bidder: 'justpremium', params: { zone: 115710 }},
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_desktop_next_Leader_bellowTitle' } },
        //{ bidder: 'richaudience', params: { pid: 'paeUO1Jbal', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 852860 } },
        { bidder: 'teads', params: {pageId: '113723', placementId: '131124'} },
        { bidder: 'triplelift',  params: { inventoryCode: "TravelcatchersFR_NEXT_Leader_bellowTitle_Prebid" } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116575} },
        { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId:  '1493292' } },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
        { bidder: 'sharethrough', params: {pkey: 'zhF74ri2aqvFlWV5hzrQN8LP'} }
      ],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement:`${LeaderBelowTitlePathway}` } },
        { bidder: 'adform', params: { mid: 980018 } },
        // { bidder: 'appnexus', params: { placementId: '20942249'  }  },
        { bidder: 'criteo', params: { networkId: 8147 } },
        //{ bidder: 'districtm', params: { placementId: 16005304  } },
        //{ bidder: 'districtmDMX', params: { dmxid: 367610, memberid : 101868 } },
        { bidder: 'ix', params: {siteId: 623544, size: [300,250]} },
        { bidder: 'ix', params: {siteId: 623544, size: [336,280]} },
        //{ bidder: 'ix', params: {siteId: 623544, size: [320,50]} },
        { bidder: 'ix', params: {siteId: 623544, size: [320,100]} },
        //{ bidder: 'ix', params: {siteId: 342243, size: [300,100]} },
        //{ bidder: 'justpremium', params: { zone: 115710 }},
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        //{ bidder: 'openx',  params: { unit: '540973692', delDomain: 'lqr-d.openx.net' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_mobile_next_Leader_bellowTitle' } },
        //{ bidder: 'richaudience', params: { pid: '0N7ZcV2Atl', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 852858 } },
        { bidder: 'teads', params: {pageId: '113723', placementId: '131124'} },
        { bidder: 'triplelift', params: { inventoryCode: "TravelcatchersFR_mobile_NEXT_Leader_bellowTitle_Prebid" } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116595} },
        { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId:  '1493292' } },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
        { bidder: 'sharethrough', params: {pkey: 'K3wWvXY0SSPK6ouuUBjIHZS1'} },
        { bidder: 'sharethrough', params: {pkey: 'Yve4m9UqTGNKIwlDr1iyjbtT'} }
      ]
    },
    {
      "condition": window.screen.width < 812,
      "id": "MPUTopRight",
      "name": MPUTopRightUnitName,
      "path": `/76730613/${MPUTopRightPathway}`,
      "sizes": [[300, 250],[336,280],[320,100]],
      "mobile_bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'MPUTopRight', environment:'mobile', placement:`${MPUTopRightPathway}` } },
        { bidder: 'adform', params: { mid: 980021 } },
        //{ bidder: 'appnexus', params: { placementId: '20942254' } },
        { bidder: 'criteo', params: { networkId: 8147 } },
        // { bidder: 'openx', params: { unit: '540973695', delDomain: 'lqr-d.openx.net' } },
        //{ bidder: 'districtm',  params: { placementId: 16005318 } },
        //{ bidder: 'districtmDMX', params: { dmxid: 367611, memberid : 101868  } },
        { bidder: 'ix', params: { siteId: 623527, size: [300, 250] } },
        { bidder: 'ix', params: { siteId: 623527, size: [336, 280] } },
        //{ bidder: 'ix', params: { siteId: 623527, size: [320, 50] } },
        { bidder: 'ix', params: { siteId: 623527, size: [320, 100] } },
        //{ bidder: 'ix', params: { siteId: 500178, size: [300, 100] } },
        //{ bidder: 'justpremium', params: { zone: 115710 }},
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_mobile_next_MPUTopRight' } },
        //{ bidder: 'richaudience', params: { pid: '0yc6bSpu0D', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 852863 } },
        { bidder: 'teads', params: {pageId: '113723', placementId: '135703'} },
        { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersFR_mobile_NEXT_MPUTopRight_Prebid"} },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116581} },
        { bidder: 'taboola', params: { tagId: 'MPUTopRight', publisherId:  '1493292' } },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
        { bidder: 'sharethrough', params: {pkey: '3mox2qjg967BVS50cuWL86GW'} },
        { bidder: 'sharethrough', params: {pkey: '0qXKwMbnXQIgF71FVHPSJBlE'} }
      ]
    },
    {
      "condition": window.screen.width > 812,
      "id": "RightColumn",
      "name": RightColumnUnitName,
      "path": `/76730613/${RightColumnPathway}`,
      "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600]] : [[160, 600]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'RightColumn', environment:'desktop', placement:`${RightColumnPathway}` } },
        { bidder: 'adform', params: { mid: 980017 } },
        //{ bidder: 'appnexus', params: { placementId: '20942259'}},
        { bidder: 'criteo', params: { networkId: 8147 } },
        //{ bidder: 'districtm', params: { placementId: 16005321 }},
        //{ bidder: 'districtmDMX', params: { dmxid: 367612, memberid:101868}},
        { bidder: 'ix', params: { siteId: 623510, size: [160, 600] }},
        // { bidder: 'ix', params: { siteId: 623510, size: [120, 600] }},
        // { bidder: 'ix', params: { siteId: 623510, size: [300, 250] }},
        // { bidder: 'ix', params: { siteId: 623510, size: [336, 280] }},
        //{ bidder: 'justpremium', params: { zone: 115710 }},
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        // { bidder: 'openx', params: { unit: '540973696', delDomain: 'lqr-d.openx.net' }},
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_desktop_next_MPUTopRight1' } },
        //{ bidder: 'richaudience', params: { pid: '13F7fkQy0c', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 852864 } },
        { bidder: 'sovrn', params:{ tagid: 852865 } },
        { bidder: 'teads', params: {pageId: '113723', placementId: '131124'} },
        { bidder: 'triplelift',  params: { inventoryCode: "TravelcatchersFR_NEXT_RightColumnHalfPage_Prebid" } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116582} },
        { bidder: 'taboola', params: { tagId: 'RightColumn', publisherId:  '1493292' } },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
        { bidder: 'sharethrough', params: {pkey: 'NxnJbG8DsfyvYcyrVhITr2Du'} }
      ]
    },
    {
      "condition": window.screen.width > 812 && !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
      "id": "LeftColumn",
      "name": LeftColumnUnitName,
      "path": `/76730613/${LeftColumnPathway}`,
      "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600]] : [[160, 600]],
      "bidders": [
        { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'LeftColumn', environment:'desktop', placement:`${LeftColumnPathway}` } },
        { bidder: 'adform', params: { mid: 996897 } },
        //{ bidder: 'appnexus', params: { placementId: '20942259'}},
        { bidder: 'criteo', params: { networkId: 8147 } },
        // { bidder: 'districtm', params: { placementId: 16005321 }},
        // { bidder: 'districtmDMX', params: { dmxid: 367612, memberid:101868}},
        { bidder: 'ix', params: { siteId: 717150, size: [160, 600] }},
        // { bidder: 'ix', params: { siteId: 717150, size: [300, 250] }},
        // { bidder: 'ix', params: { siteId: 717150, size: [120, 600] }},
        // { bidder: 'ix', params: { siteId: 717150, size: [336, 280] }},
        // { bidder: 'justpremium', params: { zone: 115710 }},
        { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
        // { bidder: 'openx', params: { unit: '540973696', delDomain: 'lqr-d.openx.net' }},
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_NEXT_desktop_LeftColumn' } },
        // { bidder: 'richaudience', params: { pid: '13F7fkQy0c', supplyType: 'site' }},
        { bidder: 'sovrn', params:{ tagid: 866882 } },
        { bidder: 'sovrn', params:{ tagid: 866881 } },
        { bidder: 'teads', params: {pageId: '113723', placementId: '131124'} },
        { bidder: 'triplelift',  params: { inventoryCode: "TravelcatchersFR_desktop_NEXT_RightColumn1_Prebid" } },
        { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
        { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116607} },
        { bidder: 'taboola', params: { tagId: 'LeftColumn', publisherId:  '1493292' } },
        { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
        { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
        { bidder: 'sharethrough', params: {pkey: 'YzuikD4DORzQLdAEsCHfeAV1'} }
      ]
    }
  ];
}

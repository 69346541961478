import React from "react";
import { DebugModeLog, runAbTest } from "../utils";

const defaultTracking = {
  utm_source: "nosource",
  utm_source2: false,
  utm_campaign: false,
  utm_medium: false,
  utm_content: false,
  utm_term: false,
  abtest: false,
};

const TrackingContext = React.createContext(defaultTracking);

class TrackingProvider extends React.Component {
  tracking = {
    utm_source: "nosource",
    utm_source2: false,
    utm_campaign: false,
    utm_medium: false,
    utm_content: false,
    utm_term: false,
    abtest: false,
  };

  constructor() {
    super();

    this.abtest = false;

    this.setAbtest();
    this.checkLocalStorage();
    this.setTracking();
  }

  setAbtest() {
    let abtestVal;

    const params = new URLSearchParams(window.location.search);
    if (params.get("abtest")) {
      abtestVal = params.get("abtest");
    } else if (localStorage.getItem("abtest")) {
      abtestVal = localStorage.getItem("abtest");
    } else {
      abtestVal = runAbTest(0.49, 0.98, 0.98, 0.98, 0.98, 0.99);
    }

    this.abtest = abtestVal;

    DebugModeLog(abtestVal);
  }

  setTracking() {
    const params = new URLSearchParams(window.location.search);

    this.tracking = {
      utm_source: params.get("utm_source")
        ? params.get("utm_source")
        : "nosource",
      utm_source2: params.get("utm_source2")
        ? params.get("utm_source2")
        : "nosource",
      utm_campaign: params.get("utm_campaign")
        ? params.get("utm_campaign")
        : localStorage.getItem("utm_campaign"),
      utm_medium: params.get("utm_medium")
        ? params.get("utm_medium")
        : localStorage.getItem("utm_medium"),
      utm_content: params.get("utm_content")
        ? params.get("utm_content")
        : localStorage.getItem("utm_content"),
      utm_term: params.get("utm_term")
        ? params.get("utm_term")
        : localStorage.getItem("utm_term"),
      abtest: this.abtest,
    };

    /* Check Local storage for utm_source(s) */
    if (!params.get("utm_source") && localStorage.getItem("utm_source")) {
      this.tracking.utm_source = localStorage.getItem("utm_source");
    }
    if (!params.get("utm_source2") && localStorage.getItem("utm_source2")) {
      this.tracking.utm_source2 = localStorage.getItem("utm_source2");
    }

    for (const key in this.tracking) {
      localStorage.setItem(key, this.tracking[key]);
    }
  }

  checkLocalStorage() {
    if (window.location.search) return;

    for (const key in this.tracking) {
      if (localStorage.getItem(key))
        this.tracking[key] = localStorage.getItem(key);
    }
  }

  render() {
    const { children } = this.props;
    const {
      utm_source,
      utm_source2,
      utm_campaign,
      utm_medium,
      utm_content,
      utm_term,
      abtest,
    } = this.tracking;

    return (
      <TrackingContext.Provider
        value={{
          utm_source,
          utm_source2,
          utm_campaign,
          utm_medium,
          utm_content,
          utm_term,
          abtest,
        }}
      >
        {children}
      </TrackingContext.Provider>
    );
  }
}
export default TrackingContext;
export { TrackingProvider };

import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  // Define Ad Unit Pathways
  const RightColumnPathway = "TVLFR/DESK_NEXT_RightColumn";
  let LeaderBelowTitlePathway = "TVLFR/DESK_NEXT_LeaderBelowTitle";

  if (window.screen.width < 812) {
    LeaderBelowTitlePathway = "TVLFR/MOB_NEXT_LeaderBelowTitle";
  }

  // prettier-ignore
  const bidders = [
    { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'LeaderBelowTitle', environment:'desktop', placement:`${LeaderBelowTitlePathway}` } },
    { bidder: 'adform', params: { mid: 979836 } },
    //{ bidder: 'appnexus', params: { placementId: '20942249' } },
    { bidder: 'criteo', params: { networkId: 8147 } },
    //{ bidder: 'openx',  params: { unit: '540973692', delDomain: 'lqr-d.openx.net' } },
    //{ bidder: 'districtm', params: { placementId: 16005304  } },
    //{ bidder: 'districtmDMX', params: { dmxid: 367610, memberid : 101868 } },
    { bidder: 'ix', params: {siteId: 623518, size: [728,90]} },
    //{ bidder: 'justpremium', params: { zone: 115710 }},
    { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
    { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_desktop_next_Leader_bellowTitle' } },
    //{ bidder: 'richaudience', params: { pid: 'paeUO1Jbal', supplyType: 'site' }},
    { bidder: 'sovrn', params:{ tagid: 852860 } },
    { bidder: 'teads', params: {pageId: '113723', placementId: '131124'} },
    { bidder: 'triplelift',  params: { inventoryCode: "TravelcatchersFR_NEXT_Leader_bellowTitle_Prebid" } },
    { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
    { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116575} },
    { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId:  '1493292' } },
    { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
    { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
    { bidder: 'sharethrough', params: {pkey: 'zhF74ri2aqvFlWV5hzrQN8LP'} }
  ];
  // prettier-ignore
  const mobileBidders = [
    { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement:`${LeaderBelowTitlePathway}` } },
    { bidder: 'adform', params: { mid: 980018 } },
    // { bidder: 'appnexus', params: { placementId: '20942249'  }  },
    { bidder: 'criteo', params: { networkId: 8147 } },
    //{ bidder: 'districtm', params: { placementId: 16005304  } },
    //{ bidder: 'districtmDMX', params: { dmxid: 367610, memberid : 101868 } },
    { bidder: 'ix', params: {siteId: 623544, size: [300,250]} },
    { bidder: 'ix', params: {siteId: 623544, size: [336,280]} },
    //{ bidder: 'ix', params: {siteId: 623544, size: [320,50]} },
    { bidder: 'ix', params: {siteId: 623544, size: [320,100]} },
    //{ bidder: 'ix', params: {siteId: 342243, size: [300,100]} },
    //{ bidder: 'justpremium', params: { zone: 115710 }},
    { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
    //{ bidder: 'openx',  params: { unit: '540973692', delDomain: 'lqr-d.openx.net' } },
    { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_mobile_next_Leader_bellowTitle' } },
    //{ bidder: 'richaudience', params: { pid: '0N7ZcV2Atl', supplyType: 'site' }},
    { bidder: 'sovrn', params:{ tagid: 852858 } },
    { bidder: 'teads', params: {pageId: '113723', placementId: '131124'} },
    { bidder: 'triplelift', params: { inventoryCode: "TravelcatchersFR_mobile_NEXT_Leader_bellowTitle_Prebid" } },
    { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
    { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116595} },
    { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId:  '1493292' } },
    { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
    { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
    { bidder: 'sharethrough', params: {pkey: 'K3wWvXY0SSPK6ouuUBjIHZS1'} },
    { bidder: 'sharethrough', params: {pkey: 'Yve4m9UqTGNKIwlDr1iyjbtT'} }
  ];
  // prettier-ignore
  let adUnits = [
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeaderBelowTitle",
        "name": "NEXT_Leader_BellowTitle",
        "path": "/76730613/NEXT_Leader_BellowTitle",
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100], [1,1]] : [[728, 90], [1,1]],
        "bidders": bidders,
        "mobile_bidders": mobileBidders,
      },
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeaderBelowTitle_1",
        "name": "NEXT_Leader_BellowTitle",
        "path": "/76730613/NEXT_Leader_BellowTitle",
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[970, 90]],
        "mobile_bidders": mobileBidders,
      },
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeaderBelowTitle_2",
        "name": "NEXT_Leader_BellowTitle",
        "path": "/76730613/NEXT_Leader_BellowTitle",
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[970, 90]],
        "mobile_bidders": mobileBidders,
      },
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeaderBelowTitle_4",
        "name": "NEXT_Leader_BellowTitle",
        "path": "/76730613/NEXT_Leader_BellowTitle",
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[970, 90]],
        "mobile_bidders": mobileBidders,
      },
      {
        "condition": window.screen.width > 812,
        "id": "RightColumn",
        "name": "NEXT_RightColumnHalfPage1",
        "path": "/76730613/NEXT_RightColumnHalfPage1",
        "sizes": window.screen.width > 1440 ? [[300, 600]] : [[160, 600]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'RightColumn', environment:'desktop', placement:`${RightColumnPathway}` } },
          { bidder: 'adform', params: { mid: 980017 } },
          //{ bidder: 'appnexus', params: { placementId: '20942259'}},
          { bidder: 'criteo', params: { networkId: 8147 } },
          //{ bidder: 'districtm', params: { placementId: 16005321 }},
          //{ bidder: 'districtmDMX', params: { dmxid: 367612, memberid:101868}},
          { bidder: 'ix', params: { siteId: 623510, size: [160, 600] }},
          // { bidder: 'ix', params: { siteId: 623510, size: [120, 600] }},
          // { bidder: 'ix', params: { siteId: 623510, size: [300, 250] }},
          // { bidder: 'ix', params: { siteId: 623510, size: [336, 280] }},
          //{ bidder: 'justpremium', params: { zone: 115710 }},
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
         // { bidder: 'openx', params: { unit: '540973696', delDomain: 'lqr-d.openx.net' }},
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_desktop_next_MPUTopRight1' } },
          //{ bidder: 'richaudience', params: { pid: '13F7fkQy0c', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 852864 } },
          { bidder: 'sovrn', params:{ tagid: 852865 } },
          { bidder: 'teads', params: {pageId: '113723', placementId: '131124'} },
          { bidder: 'triplelift',  params: { inventoryCode: "TravelcatchersFR_NEXT_RightColumnHalfPage_Prebid" } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116582} },
          { bidder: 'taboola', params: { tagId: 'RightColumn', publisherId:  '1493292' } },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
          { bidder: 'sharethrough', params: {pkey: 'NxnJbG8DsfyvYcyrVhITr2Du'} }
        ]
      },
    ];

  /**
   * Finds left and right columns, pushes the missing bidders
   * and pushes correct bidders to the adunit
   */
  // prettier-ignore
  if (window.screen.width > 1440) {
      let rcMissingIX = adUnits.find(ad => ad.id === 'RightColumn');
      rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 607960, size:[300, 600]} });
    }
  return adUnits;
}

import React from "react";
import { graphql, Script } from "gatsby";
import { IntlProvider } from "react-intl";

import Layout from "../components/layout";
import SeoStructure from "../components/seo-structure";
import HeaderScripts from "../components/HeadScripts";
import Postslist from "../components/posts/PostsList";

import LocalesContext from "../context/LocalesContext";

export function Head({ data, pageContext }) {
  const category = data?.allWpCategory?.nodes[0];
  return (
    <>
      <HeaderScripts />
      <Script
        async
        src="https://boot.pbstck.com/v1/tag/8c704138-0cf5-43be-9bf5-416e674a06d9"
        type="text/javascript"
      />
      <SeoStructure
        title={category.name}
        description={category.description ? category.description : ""}
        slug={`${pageContext.locale}/category/${category.slug}`}
      >
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: `${category?.name}`,
              },
            ],
          })}
        </script>
      </SeoStructure>
    </>
  );
}

const Category = ({ data, pageContext }) => {
  const category = data?.allWpCategory?.nodes[0];

  return (
    <IntlProvider locale={pageContext.locale} messages={pageContext.messages}>
      <LocalesContext.Provider
        value={{
          slug: `category/${pageContext.slug}`,
          avaiableLangs: [pageContext.locale],
          pageNumber: 0,
        }}
      >
        <Layout type="category">
          <div className="category">
            <div className="category__container">
              <Postslist
                posts={category.posts.nodes}
                category={category.name}
                categorySlug={category.slug}
                limit={null}
                modifier="category"
              />
            </div>
          </div>
        </Layout>
      </LocalesContext.Provider>
    </IntlProvider>
  );
};

export default Category;

export const query = graphql`
  query ($slug: String!) {
    allWpCategory(filter: { slug: { eq: $slug } }) {
      nodes {
        name
        description
        slug
        posts {
          nodes {
            id
            slug
            title
            excerpt
            originalSlug
            date
            author {
              node {
                name
              }
            }
            featuredImage {
              node {
                title
                mediaItemUrl
                mediaDetails {
                  filteredSizes {
                    name
                    sourceUrl
                  }
                  sizes {
                    name
                  }
                }
              }
            }
            seo {
              title
              metaDesc
              focuskw
              metaKeywords
              opengraphTitle
              opengraphDescription
              opengraphImage {
                altText
                sourceUrl
                srcSet
              }
              twitterTitle
              twitterDescription
              twitterImage {
                altText
                sourceUrl
                srcSet
              }
              canonical
              cornerstone
              schema {
                articleType
                pageType
                raw
              }
            }
          }
        }
        language {
          slug
        }
      }
    }
  }
`;

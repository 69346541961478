import React, { useState } from "react";
import { useEffect } from "react";
import loadable from "@loadable/component";

import { navigate, Link } from "gatsby";
import _ from "lodash";
import { useIntl } from "react-intl";

import ChevronRightIcon from "../images/svg/icons/chevron-right-icon.svg";
import { isMobilePredicate } from "../utils";

const IndexPage = ({ data }) => {
  const LeaderBoardBannerAd = loadable(() =>
    import("./adunits/LeaderBoardBannerAd")
  );
  const BannerWidget = loadable(() => import("./vendor/BannerWidget"));
  const HeroPost = loadable(() => import("./posts/HeroPost"));
  const FeaturedPostsList = loadable(() => import("./posts/FeaturedPostsList"));
  const SidebarWidget = loadable(() => import("./vendor/SidebarWidget"));
  const CustomPostsList = loadable(() =>
    import("./customPosts/CustomPostsList")
  );
  const CustomPostsListSmall = loadable(() =>
    import("./customPosts/CustomPostsListSmall")
  );
  const SidebarAd = loadable(() => import("./adunits/SidebarAd"));
  const VideoList = loadable(() => import("./media/VideoList"));
  const VideoAd = loadable(() => import("./adunits/VideoAd"));
  const AdvertiserCTA = loadable(() => import("./widgets/AdvertiserCTA"));
  const NewsletterCTA = loadable(() => import("./widgets/NewsletterCTA"));
  const PostsList = loadable(() => import("./posts/PostsList"));
  const GoogleAds = loadable(() => import("../components/google-ads"));
  const TaboolaWidget = loadable(() =>
    import("../components/vendor/TaboolaWidget")
  );

  const [isMobile, setIsMobile] = useState(false);
  const isFullHomePage = process.env.GATSBY_FULL_HOME_PAGE === "true";
  const unFilteredAllWpCategory = data?.allWpCategory.nodes;
  const heroPostData = data?.heroPost;
  const featuredPostsData = data.featuredPosts.edges;
  const customPostsOneData = data?.customPostTypeOne.edges;
  const customPostsTwoData = data?.customPostTypeTwo.edges;
  const customPostsVideoData = data?.customPostTypeVideos;
  const { locale } = useIntl();

  const allWpCategory = unFilteredAllWpCategory.filter((category) => {
    if (
      category.slug.startsWith("featured") ||
      category.slug.startsWith("hero") ||
      category.slug.startsWith("uncategorized")
    ) {
      return null;
    }
    return category;
  });

  let taboolaPlacement = "Widget Below Next";
  let taboolaContainer = "taboola-below-article";
  let taboolaMode = "thumbnails-b";
  if (isMobile) {
    taboolaMode = "thumbnails-b";
    taboolaPlacement = "Taboola Feed Mobile";
    taboolaContainer = "TaboolaFeedMobile";
  }

  useEffect(() => {
    if (_.isEmpty(allWpCategory)) return navigate("/");

    setIsMobile(isMobilePredicate());
  }, [allWpCategory]);

  if (_.isEmpty(allWpCategory)) return null;

  return (
    <div className="indexpage">
      <GoogleAds type="home" page={1} lang={locale} />

      {isFullHomePage && heroPostData && featuredPostsData && (
        <section className="indexpage__banner">
          {!isMobile && <LeaderBoardBannerAd />}
          <BannerWidget />
        </section>
      )}

      {isFullHomePage && heroPostData && featuredPostsData && (
        <section className="indexpage__hero">
          <div className="indexpage__grid">
            <HeroPost postData={heroPostData} />
            <FeaturedPostsList postData={featuredPostsData} />
            <SidebarWidget />
          </div>
        </section>
      )}

      {isFullHomePage && customPostsOneData.length !== 0 && (
        <section className="indexpage__custom-one">
          <div className="indexpage__custom-one-container">
            <div className="indexpage__custom-title">
              <div className="indexpage__custom-text">
                <h2>{process.env.GATSBY_CUSTOM_POST_ONE_NAME}</h2>
              </div>
              <div className="indexpage__custom-link">
                <Link
                  to={`/${locale}/${process.env.GATSBY_CUSTOM_POST_ONE_SLUG}`}
                >
                  <span className="indexpage__custom-link-text">Show all</span>
                  <span className="indexpage__custom-link-icon">
                    <ChevronRightIcon />
                  </span>
                </Link>
              </div>
            </div>
            <div className="indexpage__grid">
              <CustomPostsList postData={customPostsOneData.slice(0, 5)} />
              <SidebarAd />
            </div>
          </div>
        </section>
      )}

      {isFullHomePage && customPostsVideoData.edges.length !== 0 && (
        <section className="indexpage__custom-video">
          <VideoList postData={customPostsVideoData} />
        </section>
      )}

      {isFullHomePage && customPostsTwoData.length !== 0 && (
        <section className="indexpage__custom-two">
          <div className="indexpage__container">
            <div className="indexpage__title">
              <h2>{process.env.GATSBY_CUSTOM_POST_TWO_NAME}</h2>
              <div className="indexpage__seperator"></div>
            </div>
            <div className="indexpage__grid">
              <CustomPostsListSmall postData={customPostsTwoData.slice(0, 3)} />
              <VideoAd />
              <AdvertiserCTA />
            </div>
          </div>
        </section>
      )}

      {isFullHomePage && customPostsTwoData.length !== 0 && (
        <section className="indexpage__newsletter">
          <div className="indexpage__grid">
            <NewsletterCTA />
          </div>
        </section>
      )}

      <section className="indexpage__post-categories">
        {allWpCategory.map((category, index) => (
          <div className="indexpage__post-list" key={category.id}>
            <PostsList
              posts={category.posts.nodes}
              category={category.name}
              categorySlug={category.slug}
              limit={4}
              modifier="indexpage"
            />

            {isFullHomePage && index !== 2 && isMobile ? (
              <div className="indexpage__mobile-ad">
                <LeaderBoardBannerAd index={index + 1} />
              </div>
            ) : (
              ""
            )}

            {isFullHomePage && index === 2 ? (
              <div className="indexpage__taboola">
                <TaboolaWidget
                  mode={taboolaMode}
                  container={taboolaContainer}
                  placement={taboolaPlacement}
                  utm="home"
                />
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
      </section>
    </div>
  );
};

export default IndexPage;

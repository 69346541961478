import { isBrowser, checkUtmSourceStartsWith } from "../../utils";

export function slots() {
  // Need for build version as window undefined
  if (!isBrowser()) {
    return [];
  }

  const abtest = window.localStorage.getItem("abtest");

  // Define Ad unit names
  let RightColumnUnitName = "NEXT_RightColumnHalfPage1";
  let LeftColumnUnitName = "NEXT_LeftColumnHalfPage";
  let MPUCenterRightUnitName = "NEXT_MPUCenterRight";
  let MPUTopRightUnitName = "NEXT_MPUTopRight";
  let LeaderBelowTitleUnitName = "NEXT_LeaderBelowTitle";
  let StickyBannerUnitName = "NEXT_StickyBannerDesktop";
  let MPUBelowNextUnitName = "NEXT_MPUBelowNext";
  if (window.screen.width < 812) {
    LeaderBelowTitleUnitName = "NEXT_LeaderBelowTitle";
    StickyBannerUnitName = "NEXT_StickyBannerMobile";
    MPUBelowNextUnitName = "NEXT_MPUBelowNext";
  }

  // Device Specific
  if (abtest === "ab.tvl.fr.y") {
    RightColumnUnitName = "DESK_NEXT_RightColumn";
    LeftColumnUnitName = "DESK_NEXT_LeftColumn";
    MPUCenterRightUnitName = "DESK_NEXT_MPUCenterRight";
    LeaderBelowTitleUnitName = "DESK_NEXT_LeaderBelowTitle";
    StickyBannerUnitName = "DESK_NEXT_StickyBannerDesktop";
    MPUBelowNextUnitName = "DESK_NEXT_MPUBelowNext";
    if (window.screen.width < 812) {
      MPUCenterRightUnitName = "MOB_NEXT_MPUCenterRight";
      LeaderBelowTitleUnitName = "MOB_NEXT_LeaderBelowTitle";
      StickyBannerUnitName = "MOB_NEXT_StickyBanner";
      MPUBelowNextUnitName = "MOB_NEXT_MPUBelowNext";
      MPUTopRightUnitName = "MOB_NEXT_MPUTopRight";
    }
  }
  // Define Ad Unit Pathways
  let RightColumnPathway = "NEXT_RightColumnHalfPage1";
  let LeftColumnPathway = "NEXT_LeftColumnHalfPage";
  let MPUCenterRightPathway = "NEXT_MPUCenteRight";
  let MPUTopRightPathway = "NEXT_MPUTopRight";
  let LeaderBelowTitlePathway = "NEXT_LeaderBelowTitle";
  let StickyBannerPathway = "NEXT_StickyBannerDesktop";
  let MPUBelowNextPathway = "NEXT_MPUBelowNext";
  if (window.screen.width < 812) {
    MPUTopRightPathway = "NEXT_MPUTopRight";
    MPUCenterRightPathway = "NEXT_MPUCenterRight";
    LeaderBelowTitlePathway = "NEXT_LeaderBelowTitle";
    StickyBannerPathway = "NEXT_StickyBannerMobile";
    MPUBelowNextPathway = "NEXT_MPUBelowNext";
  }

  if (abtest === "ab.tvl.fr.y") {
    RightColumnPathway = "TVLFR/DESK_NEXT_RightColumn";
    LeftColumnPathway = "TVLFR/DESK_NEXT_LeftColumn";
    MPUCenterRightPathway = "TVLFR/DESK_NEXT_MPUCenterRight";
    LeaderBelowTitlePathway = "TVLFR/DESK_NEXT_LeaderBelowTitle";
    StickyBannerPathway = "TVLFR/DESK_NEXT_StickyBannerDesktop";
    MPUBelowNextPathway = "TVLFR/DESK_NEXT_MPUBelowNext";
    if (window.screen.width < 812) {
      MPUTopRightPathway = "TVLFR/MOB_NEXT_MPUTopRight";
      MPUCenterRightPathway = "TVLFR/MOB_NEXT_MPUCenterRight";
      LeaderBelowTitlePathway = "TVLFR/MOB_NEXT_LeaderBelowTitle";
      StickyBannerPathway = "TVLFR/MOB_NEXT_StickyBannerMobile";
      MPUBelowNextPathway = "TVLFR/MOB_NEXT_MPUBelowNext";
    }
  }
  // prettier-ignore
  let adUnits = [
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeaderBelowTitle",
        "name": LeaderBelowTitleUnitName,
        "path": `/76730613/${LeaderBelowTitlePathway}`,
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[728, 90]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'LeaderBelowTitle', environment:'desktop', placement:`${LeaderBelowTitlePathway}` } },
          { bidder: 'adform', params: { mid: 979836 } },
          //{ bidder: 'appnexus', params: { placementId: '20942249' } },
          { bidder: 'criteo', params: { networkId: 8147 } },
          //{ bidder: 'openx',  params: { unit: '540973692', delDomain: 'lqr-d.openx.net' } },
          //{ bidder: 'districtm', params: { placementId: 16005304  } },
          //{ bidder: 'districtmDMX', params: { dmxid: 367610, memberid : 101868 } },
          { bidder: 'ix', params: {siteId: 623518, size: [728,90]} },
          ////////{ bidder: 'justpremium', params: { zone: 115710 }},
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_desktop_next_Leader_bellowTitle' } },
          //{ bidder: 'richaudience', params: { pid: 'paeUO1Jbal', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 852860 } },
          { bidder: 'teads', params: {pageId: '113723', placementId: '131124'} },
          { bidder: 'triplelift',  params: { inventoryCode: "TravelcatchersFR_NEXT_Leader_bellowTitle_Prebid" } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116575} },
          { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId:  '1493292' } },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
          { bidder: 'sharethrough', params: {pkey: 'zhF74ri2aqvFlWV5hzrQN8LP'} }
        ],
        "mobile_bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'LeaderBelowTitle', environment:'mobile', placement:`${LeaderBelowTitlePathway}` } },
          { bidder: 'adform', params: { mid: 980018 } },
         // { bidder: 'appnexus', params: { placementId: '20942249'  }  },
          { bidder: 'criteo', params: { networkId: 8147 } },
          //{ bidder: 'districtm', params: { placementId: 16005304  } },
          //{ bidder: 'districtmDMX', params: { dmxid: 367610, memberid : 101868 } },
          { bidder: 'ix', params: {siteId: 623544, size: [300,250]} },
          { bidder: 'ix', params: {siteId: 623544, size: [336,280]} },
          //{ bidder: 'ix', params: {siteId: 623544, size: [320,50]} },
          { bidder: 'ix', params: {siteId: 623544, size: [320,100]} },
          //{ bidder: 'ix', params: {siteId: 342243, size: [300,100]} },
          ////////{ bidder: 'justpremium', params: { zone: 115710 }},
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          //{ bidder: 'openx',  params: { unit: '540973692', delDomain: 'lqr-d.openx.net' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_mobile_next_Leader_bellowTitle' } },
          //{ bidder: 'richaudience', params: { pid: '0N7ZcV2Atl', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 852858 } },
          { bidder: 'teads', params: {pageId: '113723', placementId: '131124'} },
          { bidder: 'triplelift', params: { inventoryCode: "TravelcatchersFR_mobile_NEXT_Leader_bellowTitle_Prebid" } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116595} },
          { bidder: 'taboola', params: { tagId: 'LeaderBelowTitle', publisherId:  '1493292' } },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
          { bidder: 'sharethrough', params: {pkey: 'K3wWvXY0SSPK6ouuUBjIHZS1'} },
          { bidder: 'sharethrough', params: {pkey: 'Yve4m9UqTGNKIwlDr1iyjbtT'} }
        ]
      },
      {
        "condition": window.screen.width < 812,
        "id": "MPUTopRight",
        "name": MPUTopRightUnitName,
        "path": `/76730613/${MPUTopRightPathway}`,
        "sizes": [[300, 250],[336,280],[320,100]],
        "mobile_bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'MPUTopRight', environment:'mobile', placement:`${MPUTopRightPathway}` } },
          { bidder: 'adform', params: { mid: 980021 } },
          //{ bidder: 'appnexus', params: { placementId: '20942254' } },
          { bidder: 'criteo', params: { networkId: 8147 } },
         // { bidder: 'openx', params: { unit: '540973695', delDomain: 'lqr-d.openx.net' } },
          //{ bidder: 'districtm',  params: { placementId: 16005318 } },
          //{ bidder: 'districtmDMX', params: { dmxid: 367611, memberid : 101868  } },
          { bidder: 'ix', params: { siteId: 623527, size: [300, 250] } },
          { bidder: 'ix', params: { siteId: 623527, size: [336, 280] } },
          //{ bidder: 'ix', params: { siteId: 623527, size: [320, 50] } },
          { bidder: 'ix', params: { siteId: 623527, size: [320, 100] } },
          //{ bidder: 'ix', params: { siteId: 500178, size: [300, 100] } },
          ////////{ bidder: 'justpremium', params: { zone: 115710 }},
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_mobile_next_MPUTopRight' } },
          //{ bidder: 'richaudience', params: { pid: '0yc6bSpu0D', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 852863 } },
          { bidder: 'teads', params: {pageId: '113723', placementId: '135703'} },
          { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersFR_mobile_NEXT_MPUTopRight_Prebid"} },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116581} },
          { bidder: 'taboola', params: { tagId: 'MPUTopRight', publisherId:  '1493292' } },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
          { bidder: 'sharethrough', params: {pkey: '3mox2qjg967BVS50cuWL86GW'} },
          { bidder: 'sharethrough', params: {pkey: '0qXKwMbnXQIgF71FVHPSJBlE'} }
        ]
      },
      {
        "condition": window.screen.width > 812,
        "id": "RightColumn",
        "name": RightColumnUnitName,
        "path": `/76730613/${RightColumnPathway}`,
        "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600], [300, 250]] : [[160, 600]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'RightColumn', environment:'desktop', placement:`${RightColumnPathway}` } },
          { bidder: 'adform', params: { mid: 980017 } },
          //{ bidder: 'appnexus', params: { placementId: '20942259'}},
          { bidder: 'criteo', params: { networkId: 8147 } },
          //{ bidder: 'districtm', params: { placementId: 16005321 }},
          //{ bidder: 'districtmDMX', params: { dmxid: 367612, memberid:101868}},
          { bidder: 'ix', params: { siteId: 623510, size: [160, 600] }},
          // { bidder: 'ix', params: { siteId: 623510, size: [120, 600] }},
          // { bidder: 'ix', params: { siteId: 623510, size: [300, 250] }},
          // { bidder: 'ix', params: { siteId: 623510, size: [336, 280] }},
          //{ bidder: 'justpremium', params: { zone: 115710 }},
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          // { bidder: 'openx', params: { unit: '540973696', delDomain: 'lqr-d.openx.net' }},
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_desktop_next_MPUTopRight1' } },
          //{ bidder: 'richaudience', params: { pid: '13F7fkQy0c', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 852864 } },
          { bidder: 'sovrn', params:{ tagid: 852865 } },
          { bidder: 'teads', params: {pageId: '113723', placementId: '131124'} },
          { bidder: 'triplelift',  params: { inventoryCode: "TravelcatchersFR_NEXT_RightColumnHalfPage_Prebid" } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116582} },
          { bidder: 'taboola', params: { tagId: 'RightColumn', publisherId:  '1493292' } },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
          { bidder: 'sharethrough', params: {pkey: 'NxnJbG8DsfyvYcyrVhITr2Du'} },
          { bidder: 'sharethrough', params: {pkey: 'iY4rrRWp5mD6cMWWvLnKBekQ'} },
          { bidder: 'sharethrough', params: {pkey: 'JTHjcHQHLs5qrrzmkLVDT1mP'} },
        ]
      },
      {
        "condition": window.screen.width > 812 && !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "LeftColumn",
        "name": LeftColumnUnitName,
        "path": `/76730613/${LeftColumnPathway}`,
        "sizes": window.screen.width > 1440 ? [[300, 600], [160, 600], [300, 250]] : [[160, 600]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'LeftColumn', environment:'desktop', placement:`${LeftColumnPathway}` } },
          { bidder: 'adform', params: { mid: 996897 } },
          //{ bidder: 'appnexus', params: { placementId: '20942259'}},
          { bidder: 'criteo', params: { networkId: 8147 } },
          // { bidder: 'districtm', params: { placementId: 16005321 }},
          // { bidder: 'districtmDMX', params: { dmxid: 367612, memberid:101868}},
          { bidder: 'ix', params: { siteId: 717150, size: [160, 600] }},
          // { bidder: 'ix', params: { siteId: 717150, size: [300, 250] }},
          // { bidder: 'ix', params: { siteId: 717150, size: [120, 600] }},
          // { bidder: 'ix', params: { siteId: 717150, size: [336, 280] }},
          // //////{ bidder: 'justpremium', params: { zone: 115710 }},
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
         // { bidder: 'openx', params: { unit: '540973696', delDomain: 'lqr-d.openx.net' }},
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_NEXT_desktop_LeftColumn' } },
          // { bidder: 'richaudience', params: { pid: '13F7fkQy0c', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 866882 } },
          { bidder: 'sovrn', params:{ tagid: 866881 } },
          { bidder: 'teads', params: {pageId: '113723', placementId: '131124'} },
          { bidder: 'triplelift',  params: { inventoryCode: "TravelcatchersFR_desktop_NEXT_RightColumn1_Prebid" } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116607} },
          { bidder: 'taboola', params: { tagId: 'LeftColumn', publisherId:  '1493292' } },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
          { bidder: 'sharethrough', params: {pkey: 'YzuikD4DORzQLdAEsCHfeAV1'} },
          { bidder: 'sharethrough', params: {pkey: 'EeZaDRSBomVK4kXMTs81jDMW'} },
          { bidder: 'sharethrough', params: {pkey: 'Hm437UQRVGaWMAX2cRDAjYgF'} },
        ]
      },
      {
        "condition": !checkUtmSourceStartsWith('twtr_') && !checkUtmSourceStartsWith('yahoo_'),
        "id": "StickyBanner",
        "name": StickyBannerUnitName,
        "path": `/76730613/${StickyBannerPathway}`,
        "sizes": window.screen.width < 812 ? [[320, 50],[300,50],[360,50],[320,100],[300,100],[360,100]] : [[728, 90]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'StickyBanner', environment:'desktop', placement:`${StickyBannerPathway}` } },
          { bidder: 'adform', params: { mid: 1122081 } },
          //{ bidder: 'appnexus', params: { placementId: '20942258' } },
          { bidder: 'criteo', params: { networkId: 8147 } },
          // { bidder: 'districtm', params: { placementId: 18069185} },
          // { bidder: 'districtmDMX',params: {dmxid: 479167, memberid: 101868} },
          { bidder: 'ix', params: {siteId: 717146, size: [728,90]} },
           //////{ bidder: 'justpremium', params: {zone: 115710, allow: ["ms"]} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          //{ bidder: 'openx', params: { unit: '540973702', delDomain: 'lqr-d.openx.net' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_desktop_NEXT_stickybanner' } },
          // { bidder: 'richaudience', params: { pid: '0mQBTSlzWP', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 950692 } },
          { bidder: 'teads', params: {pageId: '113723', placementId: '131124'} },
          { bidder: 'triplelift', params: { inventoryCode: "TravelcatchersFR_desktop_NEXT_stickybanner_Prebid"} },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116613} },
          { bidder: 'taboola', params: { tagId: 'StickyBanner', publisherId:  '1493292' } },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
          { bidder: 'sharethrough', params: {pkey: 'yREgllc1btMhoUnlpDPwUDVI'} }
        ],
        "mobile_bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'StickyBanner', environment:'mobile', placement:`${StickyBannerPathway}` } },
          { bidder: 'adform', params: { mid: 980027 } },
          //{ bidder: 'appnexus', params: { placementId: '20942258' } },
          { bidder: 'criteo', params: { networkId: 8147 } },
          //{ bidder: 'districtm', params: { placementId: 18069185} },
          //{ bidder: 'districtmDMX',params: {dmxid: 479167, memberid: 101868} },
          { bidder: 'ix', params: {siteId: 623555, size: [320,50]} },
          { bidder: 'ix', params: {siteId: 623555, size: [300,50]} },
          { bidder: 'ix', params: {siteId: 623555, size: [360,50]} },
          { bidder: 'ix', params: {siteId: 438878, size: [360,50]} },
          ////{ bidder: 'justpremium', params: {zone: 115710, allow: ["ms"]} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          //{ bidder: 'openx', params: { unit: '540973702', delDomain: 'lqr-d.openx.net' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_mobile_next_StickyBannerMobile' } },
          //{ bidder: 'richaudience', params: { pid: '0mQBTSlzWP', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 852866 } },
          { bidder: 'teads', params: {pageId: '113723', placementId: '131124'} },
          { bidder: 'triplelift', params: { inventoryCode: "TravelcatchersFR_NEXT_StickyBannerMobile_Prebid"} },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116614} },
          { bidder: 'taboola', params: { tagId: 'StickyBanner', publisherId:  '1493292' } },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
          { bidder: 'sharethrough', params: {pkey: 'wyvJRe4Z8d54iRlKb1DQPvol'} },
          { bidder: 'sharethrough', params: {pkey: 'vWIQLtCVKzCxlCL7bPax871j'} }
        ]
      },
      {
        "id": "MPUBelowNext",
        "name":  MPUBelowNextUnitName,
        "path": `/76730613/${MPUBelowNextPathway}`,
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100]] : [[300, 250],[336,280]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'MPUBelowNext', environment:'desktop', placement:`${MPUBelowNextPathway}` } },
          { bidder: 'adform', params: { mid: 980014 } },
          //{ bidder: 'appnexus', params: {placementId: '20942268'} },
          { bidder: 'criteo', params: { networkId: 8147 } },
          //{ bidder: 'districtm', params: {placementId: 16152398} },
          //{ bidder: 'districtmDMX', params: {dmxid: 374439, memberid : 101868} },
          { bidder: 'ix', params: {siteId: 623505, size:[300, 250]} },
          { bidder: 'ix', params: {siteId: 623505, size:[336, 280]} },
          //////{ bidder: 'justpremium', params: { zone: 115710 }},
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          //{ bidder: 'openx', params: {unit: '540973697', delDomain: 'lqr-d.openx.net'} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_desktop_next_MPUBelowNext' } },
          //{ bidder: 'richaudience', params: { pid: '0fhrpKW7Wt', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 852861 } },
          { bidder: 'teads', params: {pageId: '113723', placementId: '131124'} },
          { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersFR_NEXT_MPUBelowNext_Prebid"} },
          { bidder: 'vidazoo', params: { cId: '627a76c79d0248fca7853d08', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116579} },
          { bidder: 'taboola', params: { tagId: 'MPUBelowNext', publisherId:  '1493292' } },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
          { bidder: 'sharethrough', params: {pkey: 's7BUPVq6OrNvkwvwAGoKPdkA'} },
        ],
        "mobile_bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'MPUBelowNext', environment:'mobile', placement:`${MPUBelowNextPathway}` } },
          { bidder: 'adform', params: { mid: 980019 } },
          //{ bidder: 'appnexus', params: {placementId: '21066795'} },
          { bidder: 'criteo', params: { networkId: 8147 } },
          { bidder: 'ix', params: {siteId: 717151, size:[300, 250]} },
          { bidder: 'ix', params: {siteId: 717151, size:[336, 280]} },
          //  ////{ bidder: 'justpremium', params: {zone: 117362} },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          //{ bidder: 'openx', params: {unit: '544009993', delDomain: 'lqr-d.openx.net'} },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_NEXT_mweb_MPUbelownext' } },
          { bidder: 'sovrn', params:{ tagid: 950688 } },
          { bidder: 'sovrn', params:{ tagid: 950689 } },
          { bidder: 'teads', params: {pageId: '113723', placementId: '135704'} },
          { bidder: 'triplelift', params: { inventoryCode: 'TravelcatchersFR_mobile_NEXT_MPUBelowNext_Prebid' } },
          //  { bidder: 'yieldone', params: {placementId: '127668'} },
          //  { bidder: 'sharethrough', params: {pkey: 'UgnPWZt7lbo7knFUHK7QPtBo'} },
          //  { bidder: 'sharethrough', params: {pkey: 'eMj9cc2GRVPMrup8JnK5kN0t'} }
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116596} },
          { bidder: 'taboola', params: { tagId: 'MPUBelowNext', publisherId:  '1493292' } },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
          { bidder: 'sharethrough', params: {pkey: 'lQF1AGpSrrVgcQBtLRGVnVGA'} },
          { bidder: 'sharethrough', params: {pkey: '7u1D5K3tQxi8TFLCezoQxATE'} }
        ]
      },
      {
        "id": "MPUCenterRight",
        "name": MPUCenterRightUnitName,
        "path": `/76730613/${MPUCenterRightPathway}`,
        "sizes": window.screen.width < 812 ? [[300, 250],[336,280],[320,100],[1,1]] : [[300, 250],[336,280],[1,1]],
        "bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'MPUCenterRight', environment:'desktop', placement:`${MPUCenterRightPathway}` } },
          { bidder: 'adform', params: { mid: 980015 } },
          //{ bidder: 'appnexus', params: { placementId: '20942256'  } },
          { bidder: 'criteo', params: { networkId: 8147 } },
          { bidder: 'ix', params: {  siteId: 623513, size: [300, 250] } },
          { bidder: 'ix', params: {  siteId: 623513, size: [336, 280] } },
          ////{ bidder: 'justpremium', params: { zone: 115710 }},
          //{ bidder: 'districtm',  params: { placementId: 16005365 } },
          //{ bidder: 'districtmDMX', params: {  dmxid: 367616, memberid : 101868 } },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          //{ bidder: 'openx', params: { unit: '540973694',  delDomain: 'lqr-d.openx.net'  } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_desktop_next_MPUCenterRight' } },
          //{ bidder: 'richaudience', params: { pid: '1k5bcGF8Lx', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 852862 } },
          { bidder: 'teads', params: {pageId: '113723', placementId: '131124'} },
          { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersFR_NEXT_MPUCenterRight_Prebid"} },
          { bidder: 'vidazoo', params: { cId: '627a76c79d0248fca7853d08', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116580} },
          { bidder: 'taboola', params: { tagId: 'MPUCenterRight', publisherId:  '1493292' } },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
          { bidder: 'sharethrough', params: {pkey: '309yTcQkhMPpQbc8L9q9m1jT'} }
        ],
        "mobile_bidders": [
          { bidder: 'adagio', params: { organizationId:'1067', site:'travelcatchers-fr', adUnitElementId: 'MPUCenterRight', environment:'mobile', placement:`${MPUCenterRightPathway}` } },
          { bidder: 'adform', params: { mid: 980020 } },
          //{ bidder: 'appnexus', params: { placementId: '20942256'  } },
          { bidder: 'criteo', params: { networkId: 8147 } },
          //{ bidder: 'openx', params: { unit: '540973694',  delDomain: 'lqr-d.openx.net'  } },
          { bidder: 'ix', params: {  siteId: 623540, size: [300, 250] } },
          { bidder: 'ix', params: {  siteId: 623540, size: [336, 280] } },
          //{ bidder: 'ix', params: {  siteId: 623540, size: [320, 50] } },
          { bidder: 'ix', params: {  siteId: 623540, size: [320, 100] } },
          //{ bidder: 'ix', params: {  siteId: 342236, size: [300, 100] } },
          ////{ bidder: 'justpremium', params: { zone: 115710, allow: ["ms"] }},
          //{ bidder: 'districtm',  params: { placementId: 16005365 } },
          //{ bidder: 'districtmDMX', params: {  dmxid: 367616, memberid : 101868 } },
          { bidder: 'onetag', params: { pubId: '654b33df39ee492' }},
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: 'tvl_fr_mobile_next_MPUCenterRight' } },
          //{ bidder: 'richaudience', params: { pid: '0bPcLlibPK', supplyType: 'site' }},
          { bidder: 'sovrn', params:{ tagid: 852862 } },
          { bidder: 'teads', params: {pageId: '113723', placementId: '135704'} },
          { bidder: 'triplelift', params: {inventoryCode: "TravelcatchersFR_mobile_NEXT_MPUCenterRight_Prebid"} },
          //{ bidder: 'ogury', params: { assetKey: 'OGY-7D63387D8643', adUnitId: 'c7405b40-17e3-013b-a60e-4fbd3db95f28', skipSizeCheck: true } },
          { bidder: 'vidazoo', params: { cId: '627a472a29a8f3230ff8577f', pId: '59ac17c192832d0011283fe3', bidFloor: '0.05'} },
          { bidder: 'smartadserver', params: { siteId: 536188, pageId: 1662538, formatId: 116597} },
          { bidder: 'taboola', params: { tagId: 'MPUCenterRight', publisherId:  '1493292' } },
          { bidder: 'rise', params: { org: '62050c0300be360001986a59' } },
          { bidder: 'pubmatic', params: { publisherId: '159940', adSlot: '4847493' } },
          { bidder: 'sharethrough', params: {pkey: 'eLwAfdnd0KjeJAefxeZukY8V'} },
          { bidder: 'sharethrough', params: {pkey: 'Cu7c2IHdeJgkEDOpCovDNSZj'} },

        ]
      }
    ];

  /**
   * Finds left and right columns, pushes the missing bidders
   * and pushes correct bidders to the adunit
   */
  // prettier-ignore
  if (window.screen.width > 1440) {
    let rcMissingIX = adUnits.find(ad => ad.id === 'RightColumn');
    let lcMissingIX = adUnits.find(ad => ad.id === 'LeftColumn');
    // Right
    rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 623510, size:[300, 600]} });
    rcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 623510, size:[300, 250]} });
    rcMissingIX.bidders.push({ bidder: 'sovrn', params:{ tagid: 1103054 } });
    // Left
    lcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 717150, size:[300, 600]} });
    lcMissingIX.bidders.push({ bidder: 'ix', params: {siteId: 717150, size:[300, 250]} });
    lcMissingIX.bidders.push({ bidder: 'sharethrough', params: {pkey: 'vkBE3aZszf2HM13v2nZXCfzF'} });
    lcMissingIX.bidders.push({ bidder: 'sovrn', params:{ tagid: 1103056 } });
  }
  return adUnits;
}

import React, { useEffect } from "react";
import { loadScript } from "../../utils";

function Vidazoo({ vidazooId, utm }) {
  useEffect(() => {
    const vidazooContainer = document.getElementById("vidazooContainerJS");
    const script = document.createElement("script");
    script.id = "vidazooJS";
    script.src = `https://static.vidazoo.com/basev/vwpt.js`;
    script.setAttribute("data-widget-id", vidazooId);
    script.setAttribute(
      "data-on-widget-ad-impression",
      `onVidazooWidgetAdImpressionCallback`
    );
    script.setAttribute("data-ext-bi-utm_source", utm);
    if (typeof script["async"] !== "undefined") {
      script.async = true;
    }
    vidazooContainer.appendChild(script);

    loadScript(
      "",
      false,
      true,
      true,
      `
            function onVidazooWidgetAdImpressionCallback(cpm, info) {
                var adserverCurrency = "EUR"; // conversion to non USD requires the prebid currency module
                var fallbackConversionRate = 0.92; // conversion rate to use should the prebid module not be available (please make sure that a fallback conversion rate is configured in prebid as well)
                try {
                    var highestPreBid_partner = "video_unknown";
                    switch (info.adType) {
                        case "ima":
                            highestPreBid_partner = "video_AdX";
                            break;
                        case "marketplace":
                            highestPreBid_partner = "video_Vidazoo";
                            break;
                        case "pre_bid":
                            highestPreBid_partner = "video_" + info.bidderCode;
                            break;
                        default:
                            break;
                    }
                    var netCPM = info.netCpm - info.adServingFee;
                    if (netCPM > 0) {
                        if (adserverCurrency && adserverCurrency !== "USD") {
                            if (pbjs && pbjs.convertCurrency) {
                                netCPM = pbjs.convertCurrency(netCPM, "USD", adserverCurrency);
                            } else {
                                netCPM = netCPM * fallbackConversionRate;
                            }
                        }
                    }
                    var data = {
                        source: "video",
                        slotId: "vidazoo_video",
                        adUnitPath: "vidazoo_video",
                        highestPreBid: netCPM,
                        highestPreBid_partner,
                        mediaType: "vidazoo_video",
                        creative_width: info.width,
                        creative_height: info.height,
                        preBidWon: true,
                        timeToRespond: null,
                    };
                    window.assertive.logCustomImpression(data);
                } catch (error) {
                    console.error("Couldn't log custom impression", error);
                }
            }
        `
    );
  }, []);

  return <div id="vidazooContainerJS" className="vidazoo"></div>;
}

export default Vidazoo;

import React from "react";

function handleSubmit(e) {
  e.preventDefault();
  const form = document.querySelector("form");
  const formData = new FormData(form);
  console.log(formData);

  const data = Object.fromEntries(formData);
  const mailto = `mailto:hello@trendscatchers.com
    ?subject=${data.subject}
    &body=${data.message}, ${data.name}`;
  window.location.href = mailto;
}

function ContactForm() {
  return (
    <div className="contact-form">
      <form className="form form--contact" onSubmit={handleSubmit}>
        <div className="form__title">
          <h2>Send us a message</h2>
          <p>
            Please fill in the form below and a member of our team will get back
            to you.
          </p>
        </div>

        <div className="form form__field">
          <label htmlFor="first-name">Name</label>
          <input id="first-name" type="text" name="name" />
        </div>

        <div className="form form__field">
          <label htmlFor="email">Your email</label>
          <input id="email" type="text" name="email" />
        </div>

        <div className="form form__field">
          <label htmlFor="subject">Subject</label>
          <input id="subject" type="text" name="subject" />
        </div>

        <div className="form form__field">
          <label htmlFor="message">Message</label>
          <textarea id="message" type="textarea" name="message" />
        </div>

        <div className="form form__button">
          <button className="button button--large" type="submit" value="Submit">
            Submit{" "}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ContactForm;

import React, { useContext, useEffect } from "react";
import { isMobilePredicate } from "../../utils";
import TrackingContext from "../../context/TrackingContext";

function Avantis({ mobileId, desktopId }) {
  const domain = process.env.GATSBY_SHORT_DOMAIN;
  const { utm_source } = useContext(TrackingContext);

  useEffect(() => {
    const isMobile = isMobilePredicate();

    let tagid = mobileId;
    if (!isMobile) {
      tagid = desktopId;
    }
    const avantisSource = `
    https://cdn.avantisvideo.com/avm/js/video-loader.js?id=7a237ed8-1f68-4ba5-9562-72fe861be55d&tagId=${tagid}&subId=${utm_source}&=${domain}&callback=`;
    const script = document.createElement("script");
    script.id = "avantisJS";
    script.src = avantisSource;
    const avantis = document.getElementById("avantisContainerJS");
    avantis.appendChild(script);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id="avantisContainerJS" className="avantis" />;
}

export default Avantis;

import React, { useContext, memo } from "react";
import { Script } from "gatsby";
import TrackingContext from "../../context/TrackingContext";

const OutbrainWidget = memo(function ({ obWidgetID, utm, obLink, className }) {
  const { abtest } = useContext(TrackingContext);

  return (
    <>
      <Script src="//widgets.outbrain.com/outbrain.js" type="text/javascript" />
      <div className={"outbrain-widget " + className}>
        <div
          className="OUTBRAIN"
          data-src={obLink}
          data-widget-id={obWidgetID}
          data-external-id={utm}
          data-external-secondary-id={abtest}
        />
      </div>
    </>
  );
});
export default OutbrainWidget;
